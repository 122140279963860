import { bind } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'
import { EMPTY, fromEvent, map, merge, tap } from 'rxjs'

export const SUPPORT_MODAL_HASH = '#ticket-form'

const [supportModalOpenChange$, setSupportModalOpen] = createSignal<boolean>()

export { setSupportModalOpen }

const modalStateFromPopStateEvent$ =
  typeof window === 'undefined'
    ? EMPTY
    : fromEvent(window, 'popstate').pipe(map(() => window.location.hash === SUPPORT_MODAL_HASH))

const modalStateFromSignal$ =
  typeof window === 'undefined'
    ? EMPTY
    : supportModalOpenChange$.pipe(
        tap((state) => {
          if (state) {
            window.history.pushState(
              { _tag: 'TicketForm' },
              'Ticket',
              `${window.location.pathname}${SUPPORT_MODAL_HASH}`,
            )
          } else {
            window.history.replaceState({}, '', window.location.pathname)
          }
        }),
      )

export const [useSupportModalState, supportModalState$] = bind(
  merge(modalStateFromSignal$, modalStateFromPopStateEvent$),
  false,
)
