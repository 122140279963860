import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `DateTimeMillis` scalar type represents a date and time in the format of milliseconds since the Unix epoch.` */
  DateTimeMillis: number
  /** Represents a decimal number in string format. */
  Decimal: string
  /** Represents a specific year and month using the format "YYYY-MM". */
  RewardsPeriod: string
}

export type AccountDocument = {
  __typename?: 'AccountDocument'
  /** The type of the document. */
  documentType: AccountDocumentType
  /** The name of the file. */
  fileName: Scalars['String']
  /** The date and time the account document was last updated. */
  updatedAt: Scalars['DateTimeMillis']
}

export const AccountDocumentType = {
  TaxExemption: 'TAX_EXEMPTION',
  W9TaxId: 'W9_TAX_ID',
} as const

export type AccountDocumentType = (typeof AccountDocumentType)[keyof typeof AccountDocumentType]
export const AccountType = {
  Checking: 'CHECKING',
  Savings: 'SAVINGS',
} as const

export type AccountType = (typeof AccountType)[keyof typeof AccountType]
export type AutoCompleteInput = {
  limit?: InputMaybe<Scalars['Int']>
  query: Scalars['String']
}

export type AutoCompleteResults = {
  __typename?: 'AutoCompleteResults'
  aggregations: AutoCompleteResultsAggregations
  limit: Scalars['Int']
  query: Scalars['String']
  results: Array<IndexedProduct>
  suggestions: Array<Scalars['String']>
  totalCount: Scalars['Int']
}

export type AutoCompleteResultsAggregation = {
  __typename?: 'AutoCompleteResultsAggregation'
  id: Scalars['String']
  label?: Maybe<Scalars['String']>
  options: Array<AutoCompleteResultsAggregationOption>
}

export type AutoCompleteResultsAggregationOption = {
  __typename?: 'AutoCompleteResultsAggregationOption'
  count?: Maybe<Scalars['Int']>
  label?: Maybe<Scalars['String']>
  value: Scalars['ID']
}

export type AutoCompleteResultsAggregations = {
  __typename?: 'AutoCompleteResultsAggregations'
  category?: Maybe<AutoCompleteResultsAggregation>
}

/** The auto ship interval options. */
export const AutoShipOption = {
  Months_1: 'MONTHS_1',
  Months_2: 'MONTHS_2',
  Months_3: 'MONTHS_3',
} as const

export type AutoShipOption = (typeof AutoShipOption)[keyof typeof AutoShipOption]
export type CommissionsPayout = {
  __typename?: 'CommissionsPayout'
  success: Scalars['Boolean']
}

export type CreateEScriptInput = {
  /** The description of the eScript. */
  description?: InputMaybe<Scalars['String']>
  /** The name of the eScript. */
  name: Scalars['String']
  /**
   * The products in the eScript.
   * The sku should be unique within an eScript.
   */
  products: Array<NewEScriptProduct>
  /**
   * The public promo code that is used by a customer to get the discount.
   * Empty string or null means no promo code.
   */
  promoCode?: InputMaybe<Scalars['String']>
}

/** The standard ISO 4217 currency codes that represent the currencies used. */
export const CurrencyCode = {
  Cad: 'CAD',
  Usd: 'USD',
} as const

export type CurrencyCode = (typeof CurrencyCode)[keyof typeof CurrencyCode]
export type Customer = {
  __typename?: 'Customer'
  accountDocument: Array<AccountDocument>
  availableBalance?: Maybe<CustomerAvailableBalance>
  payoutMethod?: Maybe<PayoutMethod>
  /**
   * A list of rewards snapshots for the customer.
   * Only supports last:6. Any other pagination will result in an error.
   */
  rewardsSnapshots?: Maybe<RewardsSnapshotConnection>
}

export type CustomerRewardsSnapshotsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type CustomerAvailableBalance = {
  __typename?: 'CustomerAvailableBalance'
  commissionsBalance: Money
  rebateBalance: Money
  storeCredit: Money
  totalBalance: Money
}

export type DeleteAccountDocumentPayload = {
  __typename?: 'DeleteAccountDocumentPayload'
  /** Date and time the account document was deleted. */
  deletedAt: Scalars['DateTimeMillis']
}

export type DeleteEScriptPayload = {
  __typename?: 'DeleteEScriptPayload'
  /**
   * The id of the removed eScript.
   * If the eScript was not found, this will be null.
   */
  id?: Maybe<Scalars['ID']>
}

export type DeletePayoutMethodPayload = {
  __typename?: 'DeletePayoutMethodPayload'
  /** Date and time the payout method was deleted. */
  deletedAt: Scalars['DateTimeMillis']
}

export type DocumentDescription = {
  __typename?: 'DocumentDescription'
  html?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

export type EScript = Node & {
  __typename?: 'EScript'
  /** The date and time the eScript was created. */
  createdAt: Scalars['DateTimeMillis']
  /** The description of the eScript. */
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** The name of the eScript. */
  name: Scalars['String']
  /** The products in the eScript. */
  products: Array<EScriptProduct>
  /** The public promo code that is used by a customer to get the discount. */
  promoCode?: Maybe<Scalars['String']>
  /** The date and time the eScript was last updated. */
  updatedAt: Scalars['DateTimeMillis']
}

/** The connection type for EScript. */
export type EScriptConnection = {
  __typename?: 'EScriptConnection'
  /** A list of edges. */
  edges: Array<EScriptEdge>
  /** A list of nodes. */
  nodes: Array<EScript>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type EScriptEdge = {
  __typename?: 'EScriptEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: EScript
}

/** Properties by which eScripts can be ordered */
export const EScriptOrderField = {
  /** Order by created timestamp */
  CreatedAt: 'CREATED_AT',
  /** Order by updated timestamp */
  UpdatedAt: 'UPDATED_AT',
} as const

export type EScriptOrderField = (typeof EScriptOrderField)[keyof typeof EScriptOrderField]
/** Options for sorting list of eScripts */
export type EScriptOrdering = {
  /** Sort direction - default to field's natural direction */
  direction?: InputMaybe<OrderDirection>
  /** Order by field */
  field: EScriptOrderField
}

export type EScriptProduct = {
  __typename?: 'EScriptProduct'
  /** Whether the product is a single order or auto ship with the given interval. */
  autoShip?: Maybe<AutoShipOption>
  /** Product details. */
  product: Product
  /** The quantity of the product. */
  quantity: Scalars['Int']
  /** The tags of the product, including the name of the protocols it was added from. */
  tags?: Maybe<Array<Scalars['String']>>
}

export type ExactParentOrChildSkuInput = {
  /** @deprecated(reason: "Unused") */
  limit?: InputMaybe<Scalars['Int']>
  sku?: InputMaybe<Array<Scalars['String']>>
}

export type ExactParentOrChildSkuResults = {
  __typename?: 'ExactParentOrChildSkuResults'
  /** @deprecated Unused */
  limit?: Maybe<Scalars['Int']>
  query: Scalars['String']
  results: Array<IndexedProduct>
  suggestions: Array<Scalars['String']>
  totalCount: Scalars['Int']
}

export type FacetedInput = {
  filters?: InputMaybe<FacetedInputFilters>
  keywords?: InputMaybe<Scalars['String']>
  pagination?: InputMaybe<FacetedInputPagination>
  sort?: InputMaybe<FacetedInputSort>
}

export type FacetedInputFilters = {
  ailment?: InputMaybe<Array<Scalars['ID']>>
  category?: InputMaybe<Array<Scalars['ID']>>
  ingredient?: InputMaybe<Array<Scalars['ID']>>
  labelStatus?: InputMaybe<Array<Scalars['ID']>>
  nameRange?: InputMaybe<Array<Scalars['ID']>>
  productForm?: InputMaybe<Array<Scalars['ID']>>
  specialty?: InputMaybe<Array<Scalars['ID']>>
}

export type FacetedInputPagination = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type FacetedInputSort = {
  name?: InputMaybe<FacetedSortOrder>
}

export type FacetedResults = {
  __typename?: 'FacetedResults'
  aggregations: FacetedResultsAggregations
  keywords?: Maybe<Scalars['String']>
  meta: FacetedResultsMeta
  results: Array<IndexedProduct>
  suggestions: Array<Scalars['String']>
}

export type FacetedResultsAggregation = {
  __typename?: 'FacetedResultsAggregation'
  id: Scalars['String']
  label?: Maybe<Scalars['String']>
  options: Array<FacetedResultsAggregationOption>
}

export type FacetedResultsAggregationOption = {
  __typename?: 'FacetedResultsAggregationOption'
  count?: Maybe<Scalars['Int']>
  label?: Maybe<Scalars['String']>
  value: Scalars['ID']
}

export type FacetedResultsAggregations = {
  __typename?: 'FacetedResultsAggregations'
  ailment?: Maybe<FacetedResultsAggregation>
  category?: Maybe<FacetedResultsAggregation>
  ingredient?: Maybe<FacetedResultsAggregation>
  labelStatus?: Maybe<FacetedResultsAggregation>
  nameRange?: Maybe<FacetedResultsAggregation>
  productForm?: Maybe<FacetedResultsAggregation>
  specialty?: Maybe<FacetedResultsAggregation>
}

export type FacetedResultsMeta = {
  __typename?: 'FacetedResultsMeta'
  count: FacetedResultsMetaCount
  pagination: FacetedResultsMetaPagination
}

export type FacetedResultsMetaCount = {
  __typename?: 'FacetedResultsMetaCount'
  /** Total number of results */
  all: Scalars['Int']
  /** Number of results in current response */
  current: Scalars['Int']
}

export type FacetedResultsMetaPagination = {
  __typename?: 'FacetedResultsMetaPagination'
  currentPage: Scalars['Int']
  pageSize: Scalars['Int']
  totalPages: Scalars['Int']
}

export const FacetedSortOrder = {
  Asc: 'asc',
  Desc: 'desc',
} as const

export type FacetedSortOrder = (typeof FacetedSortOrder)[keyof typeof FacetedSortOrder]
/** Rich text presented as HTML or plain text. */
export type HtmlOrText = {
  __typename?: 'HtmlOrText'
  html?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

export type IndexedDocument = {
  id: Scalars['String']
  name: Scalars['String']
}

export type IndexedProduct = IndexedDocument & {
  __typename?: 'IndexedProduct'
  advisorTags: Array<Scalars['String']>
  ailments: Array<Scalars['String']>
  description: DocumentDescription
  id: Scalars['String']
  ingredients: Array<Scalars['String']>
  /** Maximum label status of child products */
  labelStatus?: Maybe<ProductLabelStatus>
  name: Scalars['String']
  priceRange?: Maybe<MoneyRange>
  retailPriceRange?: Maybe<MoneyRange>
  sanityImageJson?: Maybe<Scalars['String']>
  sanityImageUrl?: Maybe<Scalars['String']>
  shortDescription: DocumentDescription
  shortDescriptionV2?: Maybe<Scalars['String']>
  sku: Scalars['String']
  stockStatus?: Maybe<IndexedProductStockStatus>
  tagline?: Maybe<Scalars['String']>
  /** @deprecated Use `name` */
  title: Scalars['String']
  urlKey: Scalars['String']
  variants?: Maybe<IndexedProductVariants>
}

export const IndexedProductStockStatus = {
  InStock: 'inStock',
  OutOfStock: 'outOfStock',
} as const

export type IndexedProductStockStatus =
  (typeof IndexedProductStockStatus)[keyof typeof IndexedProductStockStatus]
export const IndexedProductVariantAttribute = {
  Dosage: 'dosage',
  Flavor: 'flavor',
  FlavorSize: 'flavorSize',
  Size: 'size',
} as const

export type IndexedProductVariantAttribute =
  (typeof IndexedProductVariantAttribute)[keyof typeof IndexedProductVariantAttribute]
export type IndexedProductVariants = {
  __typename?: 'IndexedProductVariants'
  summary: IndexedProductVariantsSummary
}

export type IndexedProductVariantsSummary = {
  __typename?: 'IndexedProductVariantsSummary'
  attributes: Array<IndexedProductVariantAttribute>
  values: IndexedProductVariantsSummaryValues
}

export type IndexedProductVariantsSummaryValues = {
  __typename?: 'IndexedProductVariantsSummaryValues'
  dosage?: Maybe<Array<Scalars['String']>>
  flavor?: Maybe<Array<Scalars['String']>>
  flavorSize?: Maybe<Array<Scalars['String']>>
  size?: Maybe<Array<Scalars['String']>>
}

export type Money = {
  __typename?: 'Money'
  /** Decimal money amount. */
  amount: Scalars['Decimal']
  /** Currency of the money. */
  currencyCode: CurrencyCode
}

export type MoneyRange = {
  __typename?: 'MoneyRange'
  /** Currency of the money range. */
  currencyCode: CurrencyCode
  /** The highest price in the range. */
  maximum: Scalars['Decimal']
  /** The lowest price in the range. */
  minimum: Scalars['Decimal']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Create a new eScript. */
  createEScript?: Maybe<EScript>
  /** Delete the account document for the customer. */
  deleteAccountDocument?: Maybe<DeleteAccountDocumentPayload>
  /** Delete eScript with the given id. */
  deleteEScript: DeleteEScriptPayload
  /** Delete the payout method for the customer. */
  deletePayoutMethod?: Maybe<DeletePayoutMethodPayload>
  /** Request commissions payout for the customer. */
  requestCommissionsPayout?: Maybe<RewardsPayout>
  /** Request rebates payout for customer. */
  requestRebatesPayout?: Maybe<RewardsPayout>
  /** Update eScript with the given id. */
  updateEScript?: Maybe<EScript>
  /** Update the payout method for the customer. */
  updatePayoutMethodAch?: Maybe<PayoutMethodAch>
  /** Upload a document for the customer account. */
  uploadAccountDocument?: Maybe<AccountDocument>
}

export type MutationCreateEScriptArgs = {
  input: CreateEScriptInput
}

export type MutationDeleteAccountDocumentArgs = {
  documentType: AccountDocumentType
}

export type MutationDeleteEScriptArgs = {
  id: Scalars['ID']
}

export type MutationRequestCommissionsPayoutArgs = {
  input: RewardsPayoutInput
}

export type MutationRequestRebatesPayoutArgs = {
  input: RewardsPayoutInput
}

export type MutationUpdateEScriptArgs = {
  input: UpdateEScriptInput
}

export type MutationUpdatePayoutMethodAchArgs = {
  input: UpdatePayoutMethodAchInput
}

export type MutationUploadAccountDocumentArgs = {
  input: UploadAccountDocumentInput
}

export type NewEScriptProduct = {
  /** No autoShip means single order. */
  autoShip?: InputMaybe<AutoShipOption>
  /** Quantity should be greater than 0. */
  quantity: Scalars['Int']
  /** Sku should be unique within an eScript. */
  sku: Scalars['String']
  /** No tags means remove existing when updating. */
  tags?: InputMaybe<Array<Scalars['String']>>
}

export type Node = {
  id: Scalars['ID']
}

/** Possible directions in which to order a list of items */
export const OrderDirection = {
  /** Ascending */
  Asc: 'ASC',
  /** Descending */
  Desc: 'DESC',
} as const

export type OrderDirection = (typeof OrderDirection)[keyof typeof OrderDirection]
/** Information about pagination in a connection */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
}

export type ParentProduct = {
  __typename?: 'ParentProduct'
  /** Extended product description */
  description?: Maybe<HtmlOrText>
  id: Scalars['ID']
  mainImage?: Maybe<SanityImage>
  name: Scalars['String']
  /** Products grouped under this parent product */
  products: ProductConnection
  /** Product description */
  shortDescription?: Maybe<HtmlOrText>
  sku: Scalars['String']
  urlKey: Scalars['String']
}

export type ParentProductProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProductOrdering>>
}

export type ParentProductConnection = {
  __typename?: 'ParentProductConnection'
  /** A list of edges. */
  edges: Array<ParentProductEdge>
  /** A list of nodes. */
  nodes: Array<ParentProduct>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type ParentProductEdge = {
  __typename?: 'ParentProductEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: ParentProduct
}

/** Properties by which parent products can be ordered */
export const ParentProductOrderField = {
  /** Order by label status (maximum status of child products) */
  LabelStatus: 'LABEL_STATUS',
  /** Order by name */
  Name: 'NAME',
  /** Order by score/relevance */
  Score: 'SCORE',
} as const

export type ParentProductOrderField =
  (typeof ParentProductOrderField)[keyof typeof ParentProductOrderField]
/** Options for sorting list of parent products */
export type ParentProductOrdering = {
  /** Sort direction - default to field's natural direction */
  direction?: InputMaybe<OrderDirection>
  /** "Order by" field */
  field: ParentProductOrderField
}

export type PayoutMethod = {
  /** The date and time the payout method was last updated. */
  updatedAt: Scalars['DateTimeMillis']
}

export type PayoutMethodAch = PayoutMethod & {
  __typename?: 'PayoutMethodAch'
  /** The name of the account holder. */
  accountHolderName: Scalars['String']
  /** Last 4 digits of the account number. */
  accountNumber: Scalars['String']
  /** The type of account. */
  accountType: AccountType
  /** The name of the bank. */
  bankName: Scalars['String']
  /** The routing number of the bank. */
  routingNumber: Scalars['String']
  /** The date and time the payout method was last updated. */
  updatedAt: Scalars['DateTimeMillis']
}

/** Product (or variant) - a distinct item for sale. */
export type Product = {
  __typename?: 'Product'
  id: Scalars['ID']
  /** Get label status for this product */
  labelStatus: ProductLabelStatus
  /** Main image for this product */
  mainImage?: Maybe<SanityImage>
  name: Scalars['String']
  /** Get parent product for this product */
  parentProduct: ParentProduct
  /** Get price for this product */
  price?: Maybe<Money>
  /** Get retail price for this product */
  retailPrice?: Maybe<Money>
  sku: Scalars['String']
}

/** Product (or variant) - a distinct item for sale. */
export type ProductLabelStatusArgs = {
  labelName: Scalars['String']
}

export type ProductConnection = {
  __typename?: 'ProductConnection'
  /** A list of edges. */
  edges: Array<ProductEdge>
  /** A list of nodes. */
  nodes: Array<Product>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Product
}

/** Label status for a given product */
export const ProductLabelStatus = {
  /** No label */
  Absent: 'ABSENT',
  /** Label present */
  Present: 'PRESENT',
} as const

export type ProductLabelStatus = (typeof ProductLabelStatus)[keyof typeof ProductLabelStatus]
/** Properties by which products can be ordered */
export const ProductOrderField = {
  /** Order by name */
  Name: 'NAME',
  /** Order by SKU */
  Sku: 'SKU',
} as const

export type ProductOrderField = (typeof ProductOrderField)[keyof typeof ProductOrderField]
/** Options for sorting list of products */
export type ProductOrdering = {
  /** Sort direction - default to field's natural direction */
  direction?: InputMaybe<OrderDirection>
  /** "Order by" field */
  field: ProductOrderField
}

/** Merged Query object */
export type Query = {
  __typename?: 'Query'
  autoComplete: AutoCompleteResults
  customer?: Maybe<Customer>
  /** Get an eScript by ID. */
  eScript?: Maybe<EScript>
  /** A list of eScripts created by the user. */
  eScripts: EScriptConnection
  exact: ExactParentOrChildSkuResults
  faceted: FacetedResults
  /** Get a parent product by ID */
  parentProduct?: Maybe<ParentProduct>
  /** Get a parent product by SKU */
  parentProductBySku?: Maybe<ParentProduct>
  /** Get a list of parent products */
  parentProducts: ParentProductConnection
  /** Get a product by ID */
  product?: Maybe<Product>
  /** Get a product by SKU */
  productBySku?: Maybe<Product>
  /** Get a list of products */
  products: ProductConnection
}

/** Merged Query object */
export type QueryAutoCompleteArgs = {
  enableFullCatalog?: InputMaybe<Scalars['Boolean']>
  input?: InputMaybe<AutoCompleteInput>
  limit?: InputMaybe<Scalars['Int']>
  query?: InputMaybe<Scalars['String']>
}

/** Merged Query object */
export type QueryEScriptArgs = {
  id: Scalars['ID']
}

/** Merged Query object */
export type QueryEScriptsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<EScriptOrdering>>
  query?: InputMaybe<Scalars['String']>
}

/** Merged Query object */
export type QueryExactArgs = {
  enableFullCatalog?: InputMaybe<Scalars['Boolean']>
  input?: InputMaybe<ExactParentOrChildSkuInput>
  sku?: InputMaybe<Array<Scalars['String']>>
}

/** Merged Query object */
export type QueryFacetedArgs = {
  enableFullCatalog?: InputMaybe<Scalars['Boolean']>
  filters?: InputMaybe<FacetedInputFilters>
  input?: InputMaybe<FacetedInput>
  keywords?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Array<ParentProductOrdering>>
  pagination?: InputMaybe<FacetedInputPagination>
}

/** Merged Query object */
export type QueryParentProductArgs = {
  id: Scalars['ID']
}

/** Merged Query object */
export type QueryParentProductBySkuArgs = {
  sku: Scalars['String']
}

/** Merged Query object */
export type QueryParentProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  labelName?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ParentProductOrdering>>
  query?: InputMaybe<Scalars['String']>
  skus?: InputMaybe<Array<Scalars['String']>>
}

/** Merged Query object */
export type QueryProductArgs = {
  id: Scalars['ID']
}

/** Merged Query object */
export type QueryProductBySkuArgs = {
  sku: Scalars['String']
}

/** Merged Query object */
export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<ProductOrdering>>
  skus?: InputMaybe<Array<Scalars['String']>>
}

/** The amounts for each rewards classification */
export type RewardsClassificationAmounts = {
  __typename?: 'RewardsClassificationAmounts'
  dfhWebStore: Money
  eStore: Money
  inPractice: Money
  retail: Money
  total: Money
  wellWorld: Money
  wholesale: Money
}

/** The rewards levels available. */
export const RewardsLevel = {
  Level_1: 'LEVEL_1',
  Level_2: 'LEVEL_2',
  Level_3: 'LEVEL_3',
  Level_4: 'LEVEL_4',
  Level_5: 'LEVEL_5',
  Level_6: 'LEVEL_6',
} as const

export type RewardsLevel = (typeof RewardsLevel)[keyof typeof RewardsLevel]
export type RewardsPayout = {
  __typename?: 'RewardsPayout'
  success: Scalars['Boolean']
}

export type RewardsPayoutInput = {
  /** The amount of rebates/commissions to redeem. */
  consumeAmountMax: Scalars['Decimal']
}

/** The amounts for each retail rewards classification */
export type RewardsRetailClassificationAmounts = {
  __typename?: 'RewardsRetailClassificationAmounts'
  dfhWebStore: Money
  eStore: Money
  total: Money
  wellWorld: Money
}

/** The rewards snapshot for a specific period. */
export type RewardsSnapshot = Node & {
  __typename?: 'RewardsSnapshot'
  commissionsEarned: RewardsRetailClassificationAmounts
  id: Scalars['ID']
  level: RewardsLevel
  orderCount: Scalars['Int']
  period: Scalars['RewardsPeriod']
  rebatesEarned: RewardsClassificationAmounts
  sales: RewardsClassificationAmounts
  totalEarned: RewardsTotalAmounts
}

/** The connection type for the rewards snapshots. */
export type RewardsSnapshotConnection = {
  __typename?: 'RewardsSnapshotConnection'
  /** A list of edges. */
  edges: Array<RewardsSnapshotEdge>
  /** A list of nodes. */
  nodes: Array<RewardsSnapshot>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type RewardsSnapshotEdge = {
  __typename?: 'RewardsSnapshotEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: RewardsSnapshot
}

/** The total rewards amounts that can be extended with classification amounts */
export type RewardsTotalAmounts = {
  __typename?: 'RewardsTotalAmounts'
  total: Money
}

/** Sanity image indexed in Solr */
export type SanityImage = {
  __typename?: 'SanityImage'
  json: Scalars['String']
  url: Scalars['String']
}

export type UpdateEScriptInput = {
  /** The id of the eScript to update. */
  id: Scalars['ID']
  /**
   * The products in the eScript.
   * The sku should be unique within an eScript.
   */
  products?: InputMaybe<Array<NewEScriptProduct>>
  /**
   * The public promo code that is used by a customer to get the discount.
   * Empty string means no promo code, null means no change.
   */
  promoCode?: InputMaybe<Scalars['String']>
}

export type UpdatePayoutMethodAchInput = {
  /** The name of the account holder. */
  accountHolderName?: InputMaybe<Scalars['String']>
  /** The full account number. */
  accountNumber?: InputMaybe<Scalars['String']>
  /** The type of account. */
  accountType?: InputMaybe<AccountType>
  /** The name of the bank. */
  bankName?: InputMaybe<Scalars['String']>
  /** The routing number of the bank. */
  routingNumber?: InputMaybe<Scalars['String']>
}

export type UploadAccountDocumentInput = {
  /** The type of the document. */
  documentType: AccountDocumentType
  /**
   * The url of the document.
   * Example: "s3://some-upload-bucket/path/to/file.pdf"
   */
  documentUrl: Scalars['String']
  /** The name of the file. */
  fileName: Scalars['String']
}

export type SearchAccountDocumentQueryVariables = Exact<{ [key: string]: never }>

export type SearchAccountDocumentQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'Customer'
    accountDocument: Array<{
      __typename?: 'AccountDocument'
      documentType: AccountDocumentType
      fileName: string
      updatedAt: number
    }>
  } | null
}

export type SearchAutoCompleteQueryVariables = Exact<{
  query: Scalars['String']
  limit?: InputMaybe<Scalars['Int']>
}>

export type SearchAutoCompleteQuery = {
  __typename?: 'Query'
  autoComplete: {
    __typename?: 'AutoCompleteResults'
    query: string
    limit: number
    suggestions: Array<string>
    totalCount: number
    results: Array<{
      __typename?: 'IndexedProduct'
      sanityImageJson?: string | null
      sku: string
      id: string
      name: string
      urlKey: string
      labelStatus?: ProductLabelStatus | null
      shortDescription: {
        __typename?: 'DocumentDescription'
        html?: string | null
        text?: string | null
      }
    }>
    aggregations: {
      __typename?: 'AutoCompleteResultsAggregations'
      category?: {
        __typename?: 'AutoCompleteResultsAggregation'
        id: string
        label?: string | null
        options: Array<{
          __typename?: 'AutoCompleteResultsAggregationOption'
          count?: number | null
          label?: string | null
          value: string
        }>
      } | null
    }
  }
}

export type CustomerAvailableBalanceQueryVariables = Exact<{ [key: string]: never }>

export type CustomerAvailableBalanceQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'Customer'
    availableBalance?: {
      __typename?: 'CustomerAvailableBalance'
      commissionsBalance: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
      rebateBalance: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
      storeCredit: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
      totalBalance: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
    } | null
  } | null
}

export type CreateEScriptMutationVariables = Exact<{
  input: CreateEScriptInput
}>

export type CreateEScriptMutation = {
  __typename?: 'Mutation'
  createEScript?: {
    __typename?: 'EScript'
    id: string
    createdAt: number
    description?: string | null
    name: string
    promoCode?: string | null
    updatedAt: number
    products: Array<{
      __typename?: 'EScriptProduct'
      autoShip?: AutoShipOption | null
      quantity: number
      tags?: Array<string> | null
      product: {
        __typename?: 'Product'
        id: string
        sku: string
        name: string
        mainImage?: { __typename?: 'SanityImage'; url: string } | null
      }
    }>
  } | null
}

export type DeleteAccountDocumentMutationVariables = Exact<{
  documentType: AccountDocumentType
}>

export type DeleteAccountDocumentMutation = {
  __typename?: 'Mutation'
  deleteAccountDocument?: { __typename?: 'DeleteAccountDocumentPayload'; deletedAt: number } | null
}

export type DeleteEScriptMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteEScriptMutation = {
  __typename?: 'Mutation'
  deleteEScript: { __typename?: 'DeleteEScriptPayload'; id?: string | null }
}

export type DeletePayoutMethodMutationVariables = Exact<{ [key: string]: never }>

export type DeletePayoutMethodMutation = {
  __typename?: 'Mutation'
  deletePayoutMethod?: { __typename?: 'DeletePayoutMethodPayload'; deletedAt: number } | null
}

export type EScriptQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type EScriptQuery = {
  __typename?: 'Query'
  eScript?: {
    __typename?: 'EScript'
    id: string
    description?: string | null
    promoCode?: string | null
    name: string
    products: Array<{
      __typename?: 'EScriptProduct'
      quantity: number
      tags?: Array<string> | null
      autoShip?: AutoShipOption | null
      product: {
        __typename?: 'Product'
        id: string
        sku: string
        name: string
        mainImage?: { __typename?: 'SanityImage'; url: string } | null
        parentProduct: { __typename?: 'ParentProduct'; urlKey: string }
      }
    }>
  } | null
}

export type SearchEScriptsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  direction?: InputMaybe<OrderDirection>
  query?: InputMaybe<Scalars['String']>
}>

export type SearchEScriptsQuery = {
  __typename?: 'Query'
  eScripts: {
    __typename?: 'EScriptConnection'
    edges: Array<{
      __typename?: 'EScriptEdge'
      node: {
        __typename?: 'EScript'
        id: string
        description?: string | null
        name: string
        updatedAt: number
        promoCode?: string | null
        products: Array<{
          __typename?: 'EScriptProduct'
          autoShip?: AutoShipOption | null
          quantity: number
          product: { __typename?: 'Product'; sku: string }
        }>
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  }
}

export type SearchExactParentOrChildSkuQueryVariables = Exact<{
  sku?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type SearchExactParentOrChildSkuQuery = {
  __typename?: 'Query'
  exact: {
    __typename?: 'ExactParentOrChildSkuResults'
    query: string
    limit?: number | null
    suggestions: Array<string>
    totalCount: number
    results: Array<{
      __typename?: 'IndexedProduct'
      advisorTags: Array<string>
      labelStatus?: ProductLabelStatus | null
      sanityImageJson?: string | null
      sanityImageUrl?: string | null
      sku: string
      stockStatus?: IndexedProductStockStatus | null
      id: string
      name: string
      urlKey: string
      shortDescription: {
        __typename?: 'DocumentDescription'
        html?: string | null
        text?: string | null
      }
      description: { __typename?: 'DocumentDescription'; html?: string | null }
      variants?: {
        __typename?: 'IndexedProductVariants'
        summary: {
          __typename?: 'IndexedProductVariantsSummary'
          attributes: Array<IndexedProductVariantAttribute>
          values: {
            __typename?: 'IndexedProductVariantsSummaryValues'
            size?: Array<string> | null
            dosage?: Array<string> | null
            flavor?: Array<string> | null
            flavorSize?: Array<string> | null
          }
        }
      } | null
      priceRange?: {
        __typename?: 'MoneyRange'
        currencyCode: CurrencyCode
        maximum: string
        minimum: string
      } | null
      retailPriceRange?: {
        __typename?: 'MoneyRange'
        currencyCode: CurrencyCode
        maximum: string
        minimum: string
      } | null
    }>
  }
}

export type SearchFacetedQueryVariables = Exact<{
  filters?: InputMaybe<FacetedInputFilters>
  keywords?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Array<ParentProductOrdering> | ParentProductOrdering>
  pagination?: InputMaybe<FacetedInputPagination>
}>

export type SearchFacetedQuery = {
  __typename?: 'Query'
  faceted: {
    __typename?: 'FacetedResults'
    suggestions: Array<string>
    meta: {
      __typename?: 'FacetedResultsMeta'
      count: { __typename?: 'FacetedResultsMetaCount'; all: number; current: number }
      pagination: {
        __typename?: 'FacetedResultsMetaPagination'
        totalPages: number
        currentPage: number
        pageSize: number
      }
    }
    aggregations: {
      __typename?: 'FacetedResultsAggregations'
      category?: {
        __typename?: 'FacetedResultsAggregation'
        id: string
        label?: string | null
        options: Array<{
          __typename?: 'FacetedResultsAggregationOption'
          label?: string | null
          value: string
          count?: number | null
        }>
      } | null
      labelStatus?: {
        __typename?: 'FacetedResultsAggregation'
        id: string
        label?: string | null
        options: Array<{
          __typename?: 'FacetedResultsAggregationOption'
          label?: string | null
          value: string
          count?: number | null
        }>
      } | null
      nameRange?: {
        __typename?: 'FacetedResultsAggregation'
        id: string
        label?: string | null
        options: Array<{
          __typename?: 'FacetedResultsAggregationOption'
          label?: string | null
          value: string
          count?: number | null
        }>
      } | null
      productForm?: {
        __typename?: 'FacetedResultsAggregation'
        id: string
        label?: string | null
        options: Array<{
          __typename?: 'FacetedResultsAggregationOption'
          label?: string | null
          value: string
          count?: number | null
        }>
      } | null
      ingredient?: {
        __typename?: 'FacetedResultsAggregation'
        id: string
        label?: string | null
        options: Array<{
          __typename?: 'FacetedResultsAggregationOption'
          label?: string | null
          value: string
          count?: number | null
        }>
      } | null
      ailment?: {
        __typename?: 'FacetedResultsAggregation'
        id: string
        label?: string | null
        options: Array<{
          __typename?: 'FacetedResultsAggregationOption'
          label?: string | null
          value: string
          count?: number | null
        }>
      } | null
    }
    results: Array<{
      __typename?: 'IndexedProduct'
      id: string
      name: string
      urlKey: string
      advisorTags: Array<string>
      labelStatus?: ProductLabelStatus | null
      sanityImageJson?: string | null
      sanityImageUrl?: string | null
      sku: string
      stockStatus?: IndexedProductStockStatus | null
      priceRange?: {
        __typename?: 'MoneyRange'
        currencyCode: CurrencyCode
        maximum: string
        minimum: string
      } | null
      retailPriceRange?: {
        __typename?: 'MoneyRange'
        currencyCode: CurrencyCode
        maximum: string
        minimum: string
      } | null
      shortDescription: { __typename?: 'DocumentDescription'; html?: string | null }
      description: { __typename?: 'DocumentDescription'; html?: string | null }
      variants?: {
        __typename?: 'IndexedProductVariants'
        summary: {
          __typename?: 'IndexedProductVariantsSummary'
          attributes: Array<IndexedProductVariantAttribute>
          values: {
            __typename?: 'IndexedProductVariantsSummaryValues'
            size?: Array<string> | null
            dosage?: Array<string> | null
            flavor?: Array<string> | null
            flavorSize?: Array<string> | null
          }
        }
      } | null
    }>
  }
}

export type MoneyFragment = { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }

export type NewEScriptFragment = { __typename: 'EScript'; id: string }

export type SearchParentProductBySkuQueryVariables = Exact<{
  sku: Scalars['String']
  labelName: Scalars['String']
  includeLabel: Scalars['Boolean']
}>

export type SearchParentProductBySkuQuery = {
  __typename?: 'Query'
  parentProductBySku?: {
    __typename?: 'ParentProduct'
    id: string
    name: string
    products: {
      __typename?: 'ProductConnection'
      edges: Array<{
        __typename?: 'ProductEdge'
        cursor: string
        node: {
          __typename?: 'Product'
          id: string
          labelStatus?: ProductLabelStatus
          name: string
          sku: string
          price?: { __typename?: 'Money'; currencyCode: CurrencyCode; amount: string } | null
          retailPrice?: { __typename?: 'Money'; currencyCode: CurrencyCode; amount: string } | null
        }
      }>
    }
  } | null
}

export type PayoutMethodAchQueryVariables = Exact<{ [key: string]: never }>

export type PayoutMethodAchQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'Customer'
    payoutMethod?: {
      __typename?: 'PayoutMethodAch'
      accountNumber: string
      bankName: string
      accountType: AccountType
      accountHolderName: string
      routingNumber: string
      updatedAt: number
    } | null
  } | null
}

export type ProductBySkuWithLabelStatusQueryVariables = Exact<{
  sku: Scalars['String']
  labelName: Scalars['String']
}>

export type ProductBySkuWithLabelStatusQuery = {
  __typename?: 'Query'
  productBySku?: {
    __typename?: 'Product'
    id: string
    labelStatus: ProductLabelStatus
    name: string
    sku: string
  } | null
}

export type SearchProductBySkuQueryVariables = Exact<{
  sku: Scalars['String']
}>

export type SearchProductBySkuQuery = {
  __typename?: 'Query'
  productBySku?: {
    __typename?: 'Product'
    id: string
    sku: string
    price?: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode } | null
    retailPrice?: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode } | null
    mainImage?: { __typename?: 'SanityImage'; json: string; url: string } | null
  } | null
}

export type RequestCommissionsPayoutMutationVariables = Exact<{
  input: RewardsPayoutInput
}>

export type RequestCommissionsPayoutMutation = {
  __typename?: 'Mutation'
  requestCommissionsPayout?: { __typename?: 'RewardsPayout'; success: boolean } | null
}

export type RequestRebatesPayoutMutationVariables = Exact<{
  input: RewardsPayoutInput
}>

export type RequestRebatesPayoutMutation = {
  __typename?: 'Mutation'
  requestRebatesPayout?: { __typename?: 'RewardsPayout'; success: boolean } | null
}

export type RewardsClassificationAmountsFragment = {
  __typename?: 'RewardsClassificationAmounts'
  dfhWebStore: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
  eStore: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
  inPractice: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
  retail: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
  total: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
  wellWorld: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
  wholesale: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
}

export type RewardsRetailClassificationAmountsFragment = {
  __typename?: 'RewardsRetailClassificationAmounts'
  dfhWebStore: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
  eStore: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
  total: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
  wellWorld: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
}

export type RewardsQueryVariables = Exact<{
  latestCount?: InputMaybe<Scalars['Int']>
}>

export type RewardsQuery = {
  __typename?: 'Query'
  customer?: {
    __typename?: 'Customer'
    availableBalance?: {
      __typename?: 'CustomerAvailableBalance'
      commissionsBalance: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
      rebateBalance: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
      storeCredit: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
      totalBalance: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
    } | null
    rewardsSnapshots?: {
      __typename?: 'RewardsSnapshotConnection'
      nodes: Array<{
        __typename?: 'RewardsSnapshot'
        id: string
        level: RewardsLevel
        orderCount: number
        period: string
        commissionsEarned: {
          __typename?: 'RewardsRetailClassificationAmounts'
          dfhWebStore: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          eStore: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          total: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          wellWorld: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
        }
        rebatesEarned: {
          __typename?: 'RewardsClassificationAmounts'
          dfhWebStore: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          eStore: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          inPractice: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          retail: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          total: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          wellWorld: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          wholesale: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
        }
        sales: {
          __typename?: 'RewardsClassificationAmounts'
          dfhWebStore: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          eStore: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          inPractice: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          retail: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          total: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          wellWorld: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
          wholesale: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
        }
        totalEarned: {
          __typename?: 'RewardsTotalAmounts'
          total: { __typename?: 'Money'; amount: string; currencyCode: CurrencyCode }
        }
      }>
      pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean }
    } | null
  } | null
}

export type UpdateEScriptMutationVariables = Exact<{
  input: UpdateEScriptInput
}>

export type UpdateEScriptMutation = {
  __typename?: 'Mutation'
  updateEScript?: {
    __typename?: 'EScript'
    id: string
    createdAt: number
    description?: string | null
    name: string
    promoCode?: string | null
    updatedAt: number
    products: Array<{
      __typename?: 'EScriptProduct'
      autoShip?: AutoShipOption | null
      quantity: number
      tags?: Array<string> | null
      product: {
        __typename?: 'Product'
        id: string
        sku: string
        name: string
        mainImage?: { __typename?: 'SanityImage'; url: string } | null
      }
    }>
  } | null
}

export type UpdatePayoutMethodAchMutationVariables = Exact<{
  input: UpdatePayoutMethodAchInput
}>

export type UpdatePayoutMethodAchMutation = {
  __typename?: 'Mutation'
  updatePayoutMethodAch?: {
    __typename?: 'PayoutMethodAch'
    accountNumber: string
    bankName: string
    accountType: AccountType
    accountHolderName: string
    routingNumber: string
    updatedAt: number
  } | null
}

export type UploadAccountDocumentMutationVariables = Exact<{
  input: UploadAccountDocumentInput
}>

export type UploadAccountDocumentMutation = {
  __typename?: 'Mutation'
  uploadAccountDocument?: {
    __typename?: 'AccountDocument'
    documentType: AccountDocumentType
    fileName: string
    updatedAt: number
  } | null
}

export const NewEScriptFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewEScript' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EScript' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewEScriptFragment, unknown>
export const MoneyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'money' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Money' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoneyFragment, unknown>
export const RewardsClassificationAmountsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'rewardsClassificationAmounts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RewardsClassificationAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dfhWebStore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eStore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inPractice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wellWorld' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wholesale' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'money' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Money' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RewardsClassificationAmountsFragment, unknown>
export const RewardsRetailClassificationAmountsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'rewardsRetailClassificationAmounts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RewardsRetailClassificationAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dfhWebStore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eStore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wellWorld' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'money' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Money' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RewardsRetailClassificationAmountsFragment, unknown>
export const SearchAccountDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchAccountDocument' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountDocument' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAccountDocumentQuery, SearchAccountDocumentQueryVariables>
export const SearchAutoCompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchAutoComplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoComplete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableFullCatalog' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'query' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labelStatus' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'IndexedProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'sanityImageJson' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'suggestions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAutoCompleteQuery, SearchAutoCompleteQueryVariables>
export const CustomerAvailableBalanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'customerAvailableBalance' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commissionsBalance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rebateBalance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeCredit' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalBalance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'money' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Money' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerAvailableBalanceQuery, CustomerAvailableBalanceQueryVariables>
export const CreateEScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateEScriptInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoShip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEScriptMutation, CreateEScriptMutationVariables>
export const DeleteAccountDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAccountDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountDocumentType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAccountDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAccountDocumentMutation, DeleteAccountDocumentMutationVariables>
export const DeleteEScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEScriptMutation, DeleteEScriptMutationVariables>
export const DeletePayoutMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePayoutMethod' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePayoutMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePayoutMethodMutation, DeletePayoutMethodMutationVariables>
export const EScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoShip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentProduct' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EScriptQuery, EScriptQueryVariables>
export const SearchEScriptsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchEScripts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'direction' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderDirection' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eScripts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'field' },
                          value: { kind: 'EnumValue', value: 'UPDATED_AT' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'direction' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'direction' } },
                        },
                      ],
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '10' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'products' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'autoShip' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'product' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchEScriptsQuery, SearchEScriptsQueryVariables>
export const SearchExactParentOrChildSkuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchExactParentOrChildSku' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableFullCatalog' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'query' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'retailPriceRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'IndexedProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'advisorTags' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'labelStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sanityImageJson' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sanityImageUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stockStatus' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variants' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'summary' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'values' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'size' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'dosage' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'flavor' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'flavorSize' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'suggestions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchExactParentOrChildSkuQuery,
  SearchExactParentOrChildSkuQueryVariables
>
export const SearchFacetedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchFaceted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FacetedInputFilters' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'keywords' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ParentProductOrdering' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FacetedInputPagination' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faceted' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableFullCatalog' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keywords' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'keywords' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'all' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pagination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'suggestions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labelStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nameRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productForm' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ingredient' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ailment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'retailPriceRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'html' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'html' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'advisorTags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labelStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sanityImageJson' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sanityImageUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockStatus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'values' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'dosage' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'flavor' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'flavorSize' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchFacetedQuery, SearchFacetedQueryVariables>
export const SearchParentProductBySkuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchParentProductBySku' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeLabel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentProductBySku' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'labelStatus' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'labelName' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'labelName' },
                                        },
                                      },
                                    ],
                                    directives: [
                                      {
                                        kind: 'Directive',
                                        name: { kind: 'Name', value: 'include' },
                                        arguments: [
                                          {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'if' },
                                            value: {
                                              kind: 'Variable',
                                              name: { kind: 'Name', value: 'includeLabel' },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currencyCode' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'retailPrice' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currencyCode' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchParentProductBySkuQuery, SearchParentProductBySkuQueryVariables>
export const PayoutMethodAchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'payoutMethodAch' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payoutMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PayoutMethodAch' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'routingNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PayoutMethodAchQuery, PayoutMethodAchQueryVariables>
export const ProductBySkuWithLabelStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'productBySkuWithLabelStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productBySku' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelStatus' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'labelName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'labelName' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductBySkuWithLabelStatusQuery,
  ProductBySkuWithLabelStatusQueryVariables
>
export const SearchProductBySkuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchProductBySku' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productBySku' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'retailPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mainImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'json' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchProductBySkuQuery, SearchProductBySkuQueryVariables>
export const RequestCommissionsPayoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestCommissionsPayout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RewardsPayoutInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestCommissionsPayout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestCommissionsPayoutMutation,
  RequestCommissionsPayoutMutationVariables
>
export const RequestRebatesPayoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestRebatesPayout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RewardsPayoutInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestRebatesPayout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestRebatesPayoutMutation, RequestRebatesPayoutMutationVariables>
export const RewardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'rewards' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'latestCount' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '6' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commissionsBalance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rebateBalance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeCredit' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalBalance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rewardsSnapshots' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'latestCount' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'commissionsEarned' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'rewardsRetailClassificationAmounts',
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'orderCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'period' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rebatesEarned' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'rewardsClassificationAmounts' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sales' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'rewardsClassificationAmounts' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalEarned' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'money' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'money' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Money' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'rewardsRetailClassificationAmounts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RewardsRetailClassificationAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dfhWebStore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eStore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wellWorld' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'rewardsClassificationAmounts' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RewardsClassificationAmounts' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dfhWebStore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eStore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inPractice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wellWorld' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wholesale' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'money' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RewardsQuery, RewardsQueryVariables>
export const UpdateEScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateEScriptInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoShip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEScriptMutation, UpdateEScriptMutationVariables>
export const UpdatePayoutMethodAchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePayoutMethodAch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePayoutMethodAchInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePayoutMethodAch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'routingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePayoutMethodAchMutation, UpdatePayoutMethodAchMutationVariables>
export const UploadAccountDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadAccountDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UploadAccountDocumentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadAccountDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadAccountDocumentMutation, UploadAccountDocumentMutationVariables>
